import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';

export type TransitionPhase = 'appear' | 'appearActive' | 'appearDone' | 'leave' | 'leaveActive' | 'leaveDone';

export type TransitionClassNames = {
    appear: string;
    appearActive: string;
    appearDone: string;
    leave: string;
    leaveActive: string;
    leaveDone: string;
};

export type TransitionRenderProps = {
    phase: TransitionPhase;
    className: string | undefined;
};

export type TransitionProps = {
    on: boolean;
    timeout: number;
    classNames?: TransitionClassNames;
    mountUnmount?: boolean;
    children: (props: TransitionRenderProps) => ReactNode;
};

function Transition({ on, timeout, classNames: classNamesMap, mountUnmount = false, children }: TransitionProps) {
    const [phase, setPhase] = useState<TransitionPhase | null>(mountUnmount ? null : on ? 'appearDone' : 'leaveDone');
    const [mounted, setMounted] = useState<boolean>(mountUnmount ? false : true);

    useEffect(() => {
        let doneTimeout: ReturnType<typeof setTimeout>;

        if (on) {
            if (mountUnmount) {
                setMounted(true);
            }
            setPhase('appear');
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    setPhase('appearActive');
                });
            });
            doneTimeout = setTimeout(() => {
                requestAnimationFrame(() => {
                    setPhase('appearDone');
                });
            }, timeout);
        } else {
            if (phase === 'leaveDone' || (mountUnmount && !mounted)) {
                return;
            }
            setPhase('leave');
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    setPhase('leaveActive');
                });
            });
            doneTimeout = setTimeout(() => {
                requestAnimationFrame(() => {
                    setPhase('leaveDone');
                    if (mountUnmount) {
                        requestAnimationFrame(() => {
                            setMounted(false);
                        });
                    }
                });
            }, timeout);
        }

        return () => {
            clearTimeout(doneTimeout);
        };
    }, [on]);

    const className = !!classNamesMap
        ? classNames({
              [classNamesMap.appear]: phase === 'appear' || phase === 'appearActive',
              [classNamesMap.appearActive]: phase === 'appearActive',
              [classNamesMap.appearDone]: phase === 'appearDone',
              [classNamesMap.leave]: phase === 'leave' || phase === 'leaveActive',
              [classNamesMap.leaveActive]: phase === 'leaveActive',
              [classNamesMap.leaveDone]: phase === 'leaveDone'
          })
        : undefined;

    return (
        <>
            {mounted &&
                !!phase &&
                children({
                    phase,
                    className
                })}
        </>
    );
}

export default Transition;
