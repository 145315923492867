export type SrcSetCandidate = [string] | [string, string];

export function toSrcSet(candidates: SrcSetCandidate[]): string {
    return candidates.map((candidate) => candidate.join(' ')).join(', ');
}

type CreatePixelDensitySrcSetCandidatesProps = {
    width: number;
    height: number;
    pixelDensityStart?: number;
    pixelDensityEnd?: number;
};

type CreatePixelDensitySrcSetCandidatesGetSrcCallbackProps = {
    width: number;
    height: number;
    pixelDensity: number;
};

type CreatePixelDensitySrcSetCandidatesGetSrcCallback = (
    props: CreatePixelDensitySrcSetCandidatesGetSrcCallbackProps
) => string;

export function createPixelDensitySrcSetCandidates(
    { width, height, pixelDensityStart = 1, pixelDensityEnd = 4 }: CreatePixelDensitySrcSetCandidatesProps,
    getSrc: CreatePixelDensitySrcSetCandidatesGetSrcCallback
): SrcSetCandidate[] {
    const candidates: SrcSetCandidate[] = [];

    for (let pixelDensity = pixelDensityStart; pixelDensity <= pixelDensityEnd; pixelDensity++) {
        const candidateWidth = width * pixelDensity;
        const candidateHeight = height * pixelDensity;

        candidates.push([getSrc({ width: candidateWidth, height: candidateHeight, pixelDensity }), `${pixelDensity}x`]);
    }

    return candidates;
}
