import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import { ReactNode } from 'react';
import { getMediaQuery } from '../../utils/breakpoints';
import { toSrcSet } from '../../utils/image';

import * as styles from './CorporateIdentityBackground.module.scss';

export type CorporateIdentityBackgroundSlug = 'grey' | 'green-1' | 'green-2' | 'green-3' | 'green-4';
export const corporateIdentityBackgrounds = [
    'grey',
    'green-1',
    'green-2',
    'green-3',
    'green-4'
] as CorporateIdentityBackgroundSlug[];

type StaticDataImageSize = {
    _1: { src: string };
    _2: { src: string };
    _3: { src: string };
    _4: { src: string };
};

type StaticDataImage = {
    jpg: StaticDataImageSize;
    webp: StaticDataImageSize;
};

export type StaticData = {
    grey: StaticDataImage;
    green1: StaticDataImage;
    green2: StaticDataImage;
    green3: StaticDataImage;
    green4: StaticDataImage;
};

const backgroundSlug: {
    [background in CorporateIdentityBackgroundSlug]: keyof StaticData;
} = {
    grey: 'grey',
    'green-1': 'green1',
    'green-2': 'green2',
    'green-3': 'green3',
    'green-4': 'green4'
};

export type CorporateIdentityBackgroundProps = {
    children: ReactNode;
    className?: string;
    background?: CorporateIdentityBackgroundSlug;
};

function CorporateIdentityBackground({ background = 'grey', children, className }: CorporateIdentityBackgroundProps) {
    const images = useStaticQuery<StaticData>(
        graphql`
            {
                grey: file(
                    sourceInstanceName: { eq: "images" }
                    relativePath: { eq: "ci-backgrounds/backdrop_grey.jpg" }
                ) {
                    ...ImageSizes
                }
                green1: file(
                    sourceInstanceName: { eq: "images" }
                    relativePath: { eq: "ci-backgrounds/backdrop_green1.jpg" }
                ) {
                    ...ImageSizes
                }
                green2: file(
                    sourceInstanceName: { eq: "images" }
                    relativePath: { eq: "ci-backgrounds/backdrop_green2.jpg" }
                ) {
                    ...ImageSizes
                }
                green3: file(
                    sourceInstanceName: { eq: "images" }
                    relativePath: { eq: "ci-backgrounds/backdrop_green3.jpg" }
                ) {
                    ...ImageSizes
                }
                green4: file(
                    sourceInstanceName: { eq: "images" }
                    relativePath: { eq: "ci-backgrounds/backdrop_green4.jpg" }
                ) {
                    ...ImageSizes
                }
            }

            fragment ImageSizes on File {
                jpg: childImageSharp {
                    _1: resize(width: 1400) {
                        src
                    }
                    _2: resize(width: 2800) {
                        src
                    }
                    _3: resize(width: 4200) {
                        src
                    }
                    _4: resize(width: 5600) {
                        src
                    }
                }
                webp: childImageSharp {
                    _1: resize(width: 1400, toFormat: WEBP) {
                        src
                    }
                    _2: resize(width: 2800, toFormat: WEBP) {
                        src
                    }
                    _3: resize(width: 4200, toFormat: WEBP) {
                        src
                    }
                    _4: resize(width: 5600, toFormat: WEBP) {
                        src
                    }
                }
            }
        `
    );

    const image: StaticDataImage = images[backgroundSlug[background]];

    return (
        <div className={classNames(styles.root, className)}>
            <picture className={styles.picture}>
                {getSources(image).map((source) => {
                    return <source key={JSON.stringify(source)} {...source} />;
                })}
                <img src={image.jpg._1.src} alt="" className={styles.image} />
            </picture>
            {children}
        </div>
    );
}

export default CorporateIdentityBackground;

function getSources(image: StaticDataImage) {
    return [
        {
            media: getMediaQuery('lg'),
            type: 'image/webp',
            src: image.webp._2.src,
            srcSet: toSrcSet([
                [image.webp._2.src, '1x'],
                [image.webp._3.src, '3x'],
                [image.webp._4.src, '4x']
            ])
        },
        {
            media: getMediaQuery('lg'),
            src: image.jpg._2.src,
            srcSet: toSrcSet([
                [image.jpg._2.src, '1x'],
                [image.jpg._3.src, '3x'],
                [image.jpg._4.src, '4x']
            ])
        },
        {
            media: getMediaQuery('md'),
            type: 'image/webp',
            src: image.webp._1.src,
            srcSet: toSrcSet([
                [image.webp._1.src, '1x'],
                [image.webp._2.src, '2x'],
                [image.webp._3.src, '3x']
            ])
        },
        {
            media: getMediaQuery('md'),
            src: image.jpg._1.src,
            srcSet: toSrcSet([
                [image.jpg._1.src, '1x'],
                [image.jpg._2.src, '2x'],
                [image.jpg._3.src, '3x']
            ])
        },
        {
            media: getMediaQuery('sm'),
            type: 'image/webp',
            src: image.webp._1.src,
            srcSet: toSrcSet([
                [image.webp._1.src, '1x'],
                [image.webp._2.src, '2x'],
                [image.webp._3.src, '4x']
            ])
        },
        {
            media: getMediaQuery('sm'),
            src: image.jpg._1.src,
            srcSet: toSrcSet([
                [image.jpg._1.src, '1x'],
                [image.jpg._2.src, '2x'],
                [image.jpg._3.src, '4x']
            ])
        },
        {
            media: getMediaQuery('xs'),
            type: 'image/webp',
            src: image.webp._1.src,
            srcSet: toSrcSet([
                [image.webp._1.src, '1x'],
                [image.webp._2.src, '3x']
            ])
        },
        {
            media: getMediaQuery('xs'),
            src: image.jpg._1.src,
            srcSet: toSrcSet([
                [image.jpg._1.src, '1x'],
                [image.jpg._2.src, '3x']
            ])
        },
        {
            media: getMediaQuery('xs'),
            type: 'image/webp',
            src: image.webp._1.src,
            srcSet: toSrcSet([
                [image.webp._1.src, '1x'],
                [image.webp._2.src, '2x']
            ])
        },
        {
            media: getMediaQuery('xs'),
            src: image.jpg._1.src,
            srcSet: toSrcSet([
                [image.jpg._1.src, '1x'],
                [image.jpg._2.src, '2x']
            ])
        }
    ];
}
