import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import * as styles from './Awards.module.scss';
import classNames from 'classnames';

export type AwardsItem = {
    id: string;
    publicURL: string;
    image: {
        gatsbyImageData: IGatsbyImageData;
    } | null;
    extension?: string;
};

export type AwardsProps = {
    items: AwardsItem[];
};

function Awards({ items }: AwardsProps) {
    return (
        <div className={styles.root}>
            <div className={classNames(styles.content, 'container--sm')}>
                <div className={styles.title}>{'Awards:'}</div>
                <div className={styles.items}>
                    {items.map(({ id, publicURL, image, extension }) => {
                        return (
                            <div key={id} className={styles.item}>
                                {extension === 'svg' ? (
                                    <img src={publicURL} alt="" className={styles.itemImage} />
                                ) : (
                                    <GatsbyImage image={image!.gatsbyImageData} alt="" className={styles.itemImage} />
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default Awards;
