import classNames from 'classnames';
import { GatsbyLinkProps } from 'gatsby';
import React, { ElementType } from 'react';

export type JumbotronButtonProps = {
    children: React.ReactNode;
} & (
    | ({ component: ElementType<GatsbyLinkProps<any>> } & GatsbyLinkProps<any>)
    | ({ component?: 'a' } & Required<Pick<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>> &
          Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'children'>)
);

export function JumbotronButton(props: JumbotronButtonProps) {
    const { component: Component = 'a', className, ...rest } = props;

    const LinkComponent: ElementType = Component;
    const linkProps: Record<string, any> = rest;

    return <LinkComponent className={classNames('tf-button c-header__button', className)} {...linkProps} />;
}
