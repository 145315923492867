import { Button } from '@truffls/design-system-react';
import classNames from 'classnames';
import { AnchorHTMLAttributes, ReactNode } from 'react';

import * as styles from './LinkSection.module.scss';
import { Link } from 'gatsby';

export type LinkSectionProps = {
    href: string;
    linkText: ReactNode;
    linkOnClick?: AnchorHTMLAttributes<HTMLAnchorElement>['onClick'];
    children: ReactNode;
};

function LinkSection({ href, linkText, linkOnClick, children }: LinkSectionProps) {
    const external = href.startsWith('http');

    return (
        <div className={classNames(styles.root, 'container--sm')}>
            <p>{children}</p>

            {external && (
                <Button
                    component="a"
                    typeStyle="raised"
                    variationStyle="brand"
                    className={styles.link}
                    href={href}
                    target="_blank"
                    rel="noopener"
                    onClick={linkOnClick}
                >
                    {linkText}
                </Button>
            )}
            {!external && (
                <Button
                    component={Link}
                    typeStyle="raised"
                    variationStyle="brand"
                    className={styles.link}
                    to={href}
                    onClick={linkOnClick}
                >
                    {linkText}
                </Button>
            )}
        </div>
    );
}

export default LinkSection;
