import { ReactNode, useEffect } from 'react';
import Transition from '../Transition';
import classNames from 'classnames';

import * as styles from './Alert.module.scss';

const transitionDurationInMs = parseInt(styles.transitionDurationInMs, 10);

export type AlertProps = {
    show: boolean;
    onClose: () => void;
    autoClose?: boolean;
    children: ReactNode;
};

function Alert({ show, onClose, autoClose, children }: AlertProps) {
    useEffect(() => {
        if (show && autoClose) {
            const timeout = setTimeout(() => {
                onClose();
            }, 3000);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [show]);

    return (
        <Transition
            on={show}
            timeout={transitionDurationInMs}
            classNames={{
                appear: styles.appear,
                appearActive: styles.appearActive,
                appearDone: styles.appearDone,
                leave: styles.leave,
                leaveActive: styles.leaveActive,
                leaveDone: styles.leaveDone
            }}
            mountUnmount={true}
        >
            {({ className }) => (
                <div className={classNames(styles.root, className)} aria-hidden={!show}>
                    <div className="container--sm">
                        <div className={styles.content}>
                            <div className={styles.text}>{children}</div>
                            {!autoClose && (
                                <button type="button" onClick={() => onClose()} className={styles.close}>
                                    &times;
                                    <span className="sr-only">Schließen</span>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Transition>
    );
}

export default Alert;
