import { ReactNode } from 'react';
import CorporateIdentityBackground, { CorporateIdentityBackgroundProps } from '../CorporateIdentityBackground';
import * as styles from './HeadlineWithBackground.module.scss';

export type HeadlineWithBackgroundProps = {
    background: CorporateIdentityBackgroundProps['background'];
    children: ReactNode;
    component: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    containerClassName?: string;
};

function HeadlineWithBackground({
    background,
    children,
    component: Component,
    containerClassName = 'container'
}: HeadlineWithBackgroundProps) {
    return (
        <CorporateIdentityBackground className={styles.root} background={background}>
            <div className={containerClassName}>
                <Component className={styles.text}>{children}</Component>
            </div>
        </CorporateIdentityBackground>
    );
}

export default HeadlineWithBackground;
