import config from '../styles/breakpoints/config.json';

const { breakpoints } = config;

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg';

export const BREAKPOINT_XS = 'xs';
export const BREAKPOINT_SM = 'sm';
export const BREAKPOINT_MD = 'md';
export const BREAKPOINT_LG = 'lg';

export const BREAKPOINT_WIDTHS: { [breakpoint in Breakpoint]: string } = {
    [BREAKPOINT_XS]: breakpoints.xs,
    [BREAKPOINT_SM]: breakpoints.sm,
    [BREAKPOINT_MD]: breakpoints.md,
    [BREAKPOINT_LG]: breakpoints.lg
};

export const BREAKPOINTS: { [breakpoint in Breakpoint]: string } = {
    [BREAKPOINT_XS]: `(min-width: ${breakpoints.xs})`,
    [BREAKPOINT_SM]: `(min-width: ${breakpoints.sm})`,
    [BREAKPOINT_MD]: `(min-width: ${breakpoints.md})`,
    [BREAKPOINT_LG]: `(min-width: ${breakpoints.lg})`
};

export function getMediaQuery(breakpoint: Breakpoint): string {
    return BREAKPOINTS[breakpoint];
}

export function matchesBreakpoint(breakpoint: Breakpoint): boolean {
    const mq = getMediaQuery(breakpoint);
    return window.matchMedia(mq).matches;
}
