import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';

import './Jumbotron.styles.scss';

export type JumbotronProps = {
    title: ReactNode;
    content?: ReactElement | null | undefined;
    contentClassName?: string;
    backgroundImageSrc: string;
    backgroundImageSources?: Array<{
        media?: string;
        src?: string;
        srcSet?: string;
        type?: string;
        sizes?: string;
    }>;
};

function Jumbotron({
    title,
    content,
    contentClassName,
    backgroundImageSrc,
    backgroundImageSources = []
}: JumbotronProps) {
    return (
        <header className={classNames('c-header')}>
            <picture>
                {backgroundImageSources.map((source) => {
                    return <source key={JSON.stringify(source)} {...source} />;
                })}

                <img src={backgroundImageSrc} className="c-header__background" alt="" data-nosnippet />
            </picture>

            <div className="c-header__elements-container container">
                <div className="c-header__content-container">
                    <div className={classNames('c-header__content', contentClassName)}>
                        <h1 className="c-header__title">{title}</h1>
                        {content}
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Jumbotron;
