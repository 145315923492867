import { graphql, Link, useStaticQuery } from 'gatsby';
import { AnchorHTMLAttributes, MouseEvent, MouseEventHandler, ReactNode } from 'react';

import { PdfFile } from '../../fragments/PdfFileFragment';

import filePdf1IconSrc from '../../images/icons/file-pdf-1.svg';

import * as styles from './FileDownloads.module.scss';

export type StaticQueryData = {
    absolventaMediaKit: PdfFile;
    absolventaCompanyInfo: PdfFile;
    trufflsServices: PdfFile;
};

export type FileDownload = PdfFile;

export type FileDownloadsClickHandler = (event: MouseEvent<HTMLAnchorElement>, file: FileDownload) => void;

export type FileDownloadsProps = {
    onClick?: FileDownloadsClickHandler;
};

function FileDownloads({ onClick }: FileDownloadsProps) {
    const files = useStaticQuery<StaticQueryData>(graphql`
        query FileDownloads {
            absolventaCompanyInfo: file(
                sourceInstanceName: { eq: "files" }
                relativePath: { eq: "Absolventa_Unternehmensbroschüre_2024.pdf" }
            ) {
                ...PdfFile
            }
            absolventaMediaKit: file(
                sourceInstanceName: { eq: "files" }
                relativePath: { eq: "Absolventa_Mediadaten_2024.pdf" }
            ) {
                ...PdfFile
            }
            trufflsServices: file(
                sourceInstanceName: { eq: "files" }
                relativePath: { eq: "truffls_Leistungen_2024.pdf" }
            ) {
                ...PdfFile
            }
        }
    `);

    return (
        <div className={styles.root}>
            <p>Absolventa – Ihr führendes Portal für Akademiker:innen</p>
            <FileDownloadLink
                {...makeLinkProps(files.absolventaCompanyInfo, {
                    onClick: onClick
                })}
                className={styles.link}
            >
                <img src={filePdf1IconSrc} alt="" className={styles.icon} />
                <span className={styles.name}>Unternehmensbroschüre</span>
            </FileDownloadLink>
            <FileDownloadLink
                {...makeLinkProps(files.absolventaMediaKit, {
                    onClick: onClick
                })}
                className={styles.link}
            >
                <img src={filePdf1IconSrc} alt="" className={styles.icon} />
                <span className={styles.name}>Mediadaten</span>
            </FileDownloadLink>
            <p>truffls – Ihre mobile Recruiting-Plattform für die effiziente Suche nach Fachkräften</p>
            <FileDownloadLink
                {...makeLinkProps(files.trufflsServices, {
                    onClick: onClick
                })}
                className={styles.link}
            >
                <img src={filePdf1IconSrc} alt="" className={styles.icon} />
                <span className={styles.name}>Unsere Leistungen</span>
            </FileDownloadLink>
        </div>
    );
}

export default FileDownloads;

type LinkProps = {
    href: Exclude<AnchorHTMLAttributes<HTMLAnchorElement>['href'], undefined>;
} & Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'target' | 'rel' | 'download' | 'onClick'>;

type MakeLinkPropsOtherProps = Partial<
    Omit<LinkProps, 'href' | 'download' | 'onClick'> & {
        onClick: (event: MouseEvent<HTMLAnchorElement>, file: FileDownload) => void;
    }
>;

function makeLinkProps(
    fielDownloadData: FileDownload,
    { onClick, ...otherProps }: MakeLinkPropsOtherProps = {}
): LinkProps {
    let ownOnClick: MouseEventHandler<HTMLAnchorElement> | undefined = undefined;
    if (typeof onClick === 'function') {
        ownOnClick = (event) => {
            onClick(event, fielDownloadData);
        };
    }

    return {
        href: fielDownloadData.publicURL,
        download: fielDownloadData.filename,
        onClick: ownOnClick,
        ...otherProps
    };
}

type FileDownloadLinkProps = LinkProps & {
    children: ReactNode;
    className?: string;
};

function FileDownloadLink({ href, children, ...props }: FileDownloadLinkProps) {
    const external = !!props.download || !!href.match(/^https?:\/\//);

    if (external) {
        return (
            <a href={href} {...props}>
                {children}
            </a>
        );
    }

    return (
        <Link to={href} {...props}>
            {children}
        </Link>
    );
}
